import { ProductLargeImage } from "../../product/ProductLargeImage";

export function RenewalCocoaCookies(): React.ReactNode {
  return (
    <ProductLargeImage
      url="/products/basecookies/cocoa"
      pcImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_bc_cocoa_W1920_H1080.jpg",
        alt: "BASE Cookies ココア リニューアル",
      }}
      spImage={{
        src: "https://asset.basefood.co.jp/images/bnr/bnr_bc_cocoa_W1080_H1080.jpg",
        alt: "BASE Cookies ココア リニューアル",
      }}
    />
  );
}
