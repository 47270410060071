import clsx from "clsx";

import { Skeleton } from "@/components/displays";
import { useParsedGetPoints } from "@/queries";
import { withSuspense } from "@/utils";

import styles from "./CurrentPoint.module.scss";
import { useAuth } from "../../auth";

/**
 * ログインしていない場合、ポイントがない場合は、何も表示しない
 */
export const CurrentPoint = withSuspense(
  function CurrentPoint() {
    const { isLoggedIn } = useAuth();
    const { data: points } = useParsedGetPoints({ enabled: isLoggedIn });
    const point = points?.total ?? 0;
    if (!isLoggedIn || point === 0) {
      return null;
    }
    return (
      <div className={clsx("bg__gray", styles.root)}>
        <p className={clsx("text__s", "text__center")}>
          お持ちの<span className="text__bold">{point}pt</span>でお得に購入できます
        </p>
      </div>
    );
  },
  <div className={clsx("bg__gray", styles.root)}>
    <Skeleton />
  </div>
);
