import styles from "./IframeSection.module.scss";
export function IframeSection() {
  return (
    <iframe
      src="https://www.youtube.com/embed/dZUkvPcDZbY?enablejsapi=1&rel=0"
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      className={styles.iframe}
    ></iframe>
  );
}
