import clsx from "clsx";
import { StaticImageData } from "next/image";
import Link from "next/link";

import { Image } from "@/components/displays";

import styles from "./ProductLargeImage.module.scss";

type LargeImage = {
  src: string | StaticImageData;
  alt: string;
};

interface ProductLargeImageProps {
  spImage: LargeImage;
  pcImage: LargeImage;
  url?: string;
  full?: string;
}

export function ProductLargeImage({
  spImage,
  pcImage,
  url,
  full,
}: ProductLargeImageProps): React.ReactNode {
  const imageContents = (
    <>
      <Image
        src={spImage.src}
        className={clsx("clear__pc", full && styles.fullImageSp)}
        alt={spImage.alt}
        width={390}
        height={350}
      />
      <Image src={pcImage.src} className="clear__sp" alt={pcImage.alt} width={950} height={360} />
    </>
  );

  return url ? <Link href={url}>{imageContents}</Link> : imageContents;
}
